import React, { useState, useEffect, useRef } from 'react';
import video from "./assets/Full.mp4";
import audioFile from "./assets/jazz-piano.mp3";
import './App.css';
import image from "./assets/arrow.svg"

const phases = [
  {
    text: "During this season of celebration, we reflect on the journey we've shared, learning from challenges and growing together.",
    startTime: 0,
    endTime: 1.16,
  },
  {
    text: "Your trust and partnership have been invaluable to us.",
    startTime: 1.16,
    endTime: 4.19,
  },
  {
    text: "Here's to a holiday filled with gratitude, joy, and the anticipation of continued success in the coming year.",
    startTime: 4.39,
    endTime: 6.90,
  },
  {
    text: "We wish you and your loved ones happy holidays!",
    startTime: 6.90,
    endTime: 9.70,
  },
];

const App = () => {
  const [phaseIndex, setPhaseIndex] = useState(0);
  const videoRef = useRef(null);
  const audioRef = useRef(new Audio(audioFile));
  const textContainerRef = useRef(null);

  const handleTextClick = () => {
    if (phaseIndex < phases.length - 1) {
      const textContainer = textContainerRef.current;

      textContainer.style.opacity = 0;

      setTimeout(() => {
        setPhaseIndex((prevIndex) => prevIndex + 1);
        playVideoAtIndex(phaseIndex + 1);
      }, 500);

      setTimeout(() => {
        textContainer.style.opacity = 1;
      }, 500);

      if (phaseIndex === 0) {
        audioRef.current.play();
      }
    }
  };

  const playVideoAtIndex = (index) => {
    const currentPhase = phases[index];
    if (videoRef.current) {
      videoRef.current.currentTime = currentPhase.startTime;
      videoRef.current.play();
    }
  };

  const onVideoTimeUpdate = () => {
    const currentPhase = phases[phaseIndex];
    if (videoRef.current) {
      if (videoRef.current.currentTime >= currentPhase.endTime) {
        videoRef.current.pause();
      }
    }
  };

  useEffect(() => {
    playVideoAtIndex(0);
  }, []);

  return (
    <div className="app-container">
      {phaseIndex < phases.length && (
        <div className="video-container">
          <video
            ref={videoRef}
            className="video-player"
            autoPlay={false}
            loop={false}
            muted
            playsInline
            onTimeUpdate={onVideoTimeUpdate}
            onEnded={() => setPhaseIndex((prevIndex) => prevIndex + 1)}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}

      <div
        onClick={handleTextClick}
        className="text-container"
        ref={textContainerRef}
      >
        <p className={`phase-text ${phaseIndex === 0 ? 'active' : ''}`}>
          {phases[phaseIndex].text}
        </p>
      </div>
      {phaseIndex !== 3 && <div
        className='button'
        onClick={handleTextClick}
      >
        <img src={image} alt='arrow' />
        click here
      </div>}
    </div>
  );
};

export default App;
